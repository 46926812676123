import React from 'react'
import t from 'prop-types'

const kinds = {
  info: '#5352ED',
  positive: '#2ED573',
  negative: '#FF4757',
  warning: '#FFA502',
}

export const YouTube = ({
  children,
  kind,
  ...rest
}) => ( <
  div style = {
    {
      position: 'relative',
      width: '100%',
      height: 0,
      paddingBottom: '56.25%'
    }
  } {
    ...rest
  } > {
    children
  } <
  /div>
)

YouTube.propTypes = {
  /**
   * The kind prop is used to set the alert's background color
   */
  kind: t.oneOf(['info', 'positive', 'negative', 'warning']),
}

YouTube.defaultProps = {
  kind: 'info',
}