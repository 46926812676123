// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-add-an-event-mdx": () => import("./../../../../src/Add an Event.mdx" /* webpackChunkName: "component---src-add-an-event-mdx" */),
  "component---src-add-edit-locations-mdx": () => import("./../../../../src/Add-Edit Locations.mdx" /* webpackChunkName: "component---src-add-edit-locations-mdx" */),
  "component---src-add-event-to-device-calendar-mdx": () => import("./../../../../src/Add event to device calendar.mdx" /* webpackChunkName: "component---src-add-event-to-device-calendar-mdx" */),
  "component---src-advanced-settings-mdx": () => import("./../../../../src/Advanced settings.mdx" /* webpackChunkName: "component---src-advanced-settings-mdx" */),
  "component---src-cancel-refund-unpublish-events-mdx": () => import("./../../../../src/Cancel, Refund & Unpublish events.mdx" /* webpackChunkName: "component---src-cancel-refund-unpublish-events-mdx" */),
  "component---src-cloning-events-mdx": () => import("./../../../../src/Cloning Events.mdx" /* webpackChunkName: "component---src-cloning-events-mdx" */),
  "component---src-contact-attendees-mdx": () => import("./../../../../src/Contact attendees.mdx" /* webpackChunkName: "component---src-contact-attendees-mdx" */),
  "component---src-create-brochure-mdx": () => import("./../../../../src/Create Brochure.mdx" /* webpackChunkName: "component---src-create-brochure-mdx" */),
  "component---src-dns-mdx": () => import("./../../../../src/DNS.mdx" /* webpackChunkName: "component---src-dns-mdx" */),
  "component---src-edit-and-approve-pending-events-mdx": () => import("./../../../../src/Edit and Approve Pending Events.mdx" /* webpackChunkName: "component---src-edit-and-approve-pending-events-mdx" */),
  "component---src-edit-events-mdx": () => import("./../../../../src/Edit Events.mdx" /* webpackChunkName: "component---src-edit-events-mdx" */),
  "component---src-event-export-mdx": () => import("./../../../../src/Event Export.mdx" /* webpackChunkName: "component---src-event-export-mdx" */),
  "component---src-event-series-mdx": () => import("./../../../../src/Event series.mdx" /* webpackChunkName: "component---src-event-series-mdx" */),
  "component---src-getting-started-mdx": () => import("./../../../../src/Getting Started.mdx" /* webpackChunkName: "component---src-getting-started-mdx" */),
  "component---src-impersonate-mdx": () => import("./../../../../src/Impersonate.mdx" /* webpackChunkName: "component---src-impersonate-mdx" */),
  "component---src-make-a-staff-booking-mdx": () => import("./../../../../src/Make a Staff Booking.mdx" /* webpackChunkName: "component---src-make-a-staff-booking-mdx" */),
  "component---src-manage-ap-is-mdx": () => import("./../../../../src/Manage APIs.mdx" /* webpackChunkName: "component---src-manage-ap-is-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-room-reservations-mdx": () => import("./../../../../src/Room Reservations.mdx" /* webpackChunkName: "component---src-room-reservations-mdx" */),
  "component---src-staff-permissions-mdx": () => import("./../../../../src/Staff Permissions.mdx" /* webpackChunkName: "component---src-staff-permissions-mdx" */),
  "component---src-statistics-logging-mdx": () => import("./../../../../src/Statistics Logging.mdx" /* webpackChunkName: "component---src-statistics-logging-mdx" */),
  "component---src-tips-tricks-mdx": () => import("./../../../../src/Tips-Tricks.mdx" /* webpackChunkName: "component---src-tips-tricks-mdx" */),
  "component---src-using-the-events-calendar-mdx": () => import("./../../../../src/Using the Events Calendar.mdx" /* webpackChunkName: "component---src-using-the-events-calendar-mdx" */),
  "component---src-view-statistics-mdx": () => import("./../../../../src/View statistics.mdx" /* webpackChunkName: "component---src-view-statistics-mdx" */),
  "component---src-waiting-list-mdx": () => import("./../../../../src/Waiting List.mdx" /* webpackChunkName: "component---src-waiting-list-mdx" */)
}

